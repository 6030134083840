/* prettier-ignore */
.bg-holder.overlay-1:before {
  background: rgba(0, 0, 0, 0.5);
}

.hellgruen {
  background: rgba(167, 158, 141, 0.1);
}

.secondary_hell {
  background: #f6f3f0;
}

h1, h2, h3, h4, h5 {
  color: #2b2b2b;
}

.black {
  color: black;
}

.small {
  font-size: 0.8em;
}

/* Grid gap */
.grid {
  display: flex;
}

/*Listen Hintergrund*/
.list-group-item {
  background: transparent;
}