@import "../../node_modules/bootstrap/scss/_functions";
@import "theme/functions";

@import "../../node_modules/bootstrap/scss/mixins";
@import "theme/mixins";

@import "user-variables";
@import "theme/variables";
@import "../../node_modules/bootstrap/scss/variables";

// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.bg-holder.overlay-1:before{
    background:rgba(0,0,0,0.5);

} 

.hellgruen{
    background: rgba(167, 158, 141, 0.1);
}

.secondary_hell{
background: #f6f3f0;
}

h1, h2, h3, h4, h5{
    color: #2b2b2b
}

.black{
    color:black;
}

.small{
    font-size:0.8em;
}

/* Grid gap */
.grid{
    display:flex;
}

/*Listen Hintergrund*/
.list-group-item{
    background:transparent;
}





